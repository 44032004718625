import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
  //buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const NewsAndreasPage = () => {
  return (
    <Layout pageTitle="WillkommenAndreas" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>Interview - 08. Januar 2024</p>
                <h1>
                  Andreas G. Henkel übernimmt ab Januar 2024 die
                  Schlüsselposition des Executive Director of Program and
                  Product Portfolio Management
                </h1>

                <p className="text-medium">
                  Zum Jahreswechsel freuen wir uns über frischen Wind im the
                  i-engineers-Team: Andreas G. Henkel, bisheriger CIO des
                  renommierten Universitätsklinikums rechts der Isar der TU
                  München, leitet zukünftig den Bereich Program and Produkt
                  Portfolio Management. Mit seiner langjährigen Erfahrung im
                  Gebiet digitales Krankenhausmanagement und Interoperabilität
                  wird er unser Unternehmensportfolio weiterentwickeln und
                  verfeinern.
                </p>

                <p>Hierzu haben wir ihm vorab ein paar Fragen gestellt:</p>

                <h3 className={marginTopSubstitute}>
                  Was hat Dich zum Wechsel bewogen und warum jetzt? Wie sind Sie
                  auf unser Unternehmen aufmerksam geworden?{" "}
                </h3>
                <p>
                  Nach ca. 30 Jahren in der IT im Krankenhaus und davon 17 Jahre
                  und 5 Monate im IT-Dienstleistungsbereich von
                  Universitätskliniken ist der Wechsel zu the i-engineers
                  besonders reizvoll. Denn diese Herausforderung bietet mir eine
                  einzigartige Gelegenheit, meine Fähigkeiten und mein Wissen
                  auf eine neue und spannende Weise einzusetzen. Meine
                  umfangreichen Erfahrungen in der Leitung von Großprojekten am
                  Klinikum rechts der Isar und Projekte wie die
                  S/4HANA-Umstellung oder agile Vorhaben während der Pandemie
                  haben mein Interesse an dynamischen und innovativen
                  Arbeitsumgebungen geweckt. Die Notwendigkeit, nach der
                  Pandemie ressourcenschonender zu agieren, hat mich weiter
                  motiviert, eine Position zu suchen, in der ich signifikanten
                  Einfluss auf die Entwicklung und das Management von Produkt-
                  und Projektportfolios nehmen kann. Die Rolle des Executive
                  Director of Program and Product Portfolio Management bei the
                  i-engineers bietet mir außerdem die Möglichkeit, mein Wissen
                  und meine Erfahrung in einem technologisch fortschrittlichen
                  und sich schnell entwickelnden Umfeld einzusetzen.
                </p>

                <h3 className={marginTopSubstitute}>
                  Welche Erfahrungen und Kompetenzen werden Dir bei der
                  Weiterentwicklung des Produktportfolios von the i-engineers am
                  meisten helfen?
                </h3>
                <p>
                  Die fast fünfjährige Amtszeit als Vorstand bei IHE Deutschland
                  hat mein tiefes Verständnis für das Thema Interoperabilität
                  geprägt. Diese Erfahrung, zusammen mit meiner aktiven Rolle
                  bei der Einbringung eines Anwendungsfalls ¬ISiK Version 1 und
                  der IHE XDS Nutzung mit digitalen Archivsystemen, wird mir
                  helfen, das Produktportfolio von the i-engineers strategisch
                  zu erweitern und zu verbessern. Noch entscheidender wird aber
                  mein Verständnis für die Nutzerperspektive sein, die ich
                  aufgrund meiner vorherigen Tätigkeit oft genug einnehmen
                  musste. So habe ich verstärkt darauf geachtet, dass Projekte
                  konsequent nach Nutzermehrwert und nachhaltigen Ergebnissen
                  behandelt wurden.
                </p>

                <h3 className={marginTopSubstitute}>
                  Inwiefern wird Dir dabei die Zeit am Klinikum rechts der Isar
                  besonders nützlich sein?
                </h3>
                <p>
                  Am Klinikum rechts der Isar konnte ich ein starkes Verständnis
                  für die Bedürfnisse von Ärzten und Pflegekräften entwickeln.
                  Diese Kenntnisse sind äußerst wertvoll, um die Produkte von
                  the i-engineers praxisnah und effektiv für den klinischen
                  Alltag zu gestalten. In 2023 durfte ich mit einem
                  Industriepartner zudem bereits im engen Austausch an einem KIS
                  der Zukunft mitarbeiten, was mir weitere Einblicke in
                  Cloud-Architekturen und deren Nutzung für die Abbildung eines
                  neuen Eco-Systems für ein Krankenhaus gebracht hat. Diese
                  Erfahrungen in Verbindung mit der Möglichkeit, dass man auch
                  im Partnermanagement mit dem Industrieanbieter kooperiert,
                  sind für mich extrem wertvoll.
                </p>

                <h3 className={marginTopSubstitute}>
                  Hast du Dir bereits konkrete Gedanken gemacht, wie Du den
                  Nutzen der health-engine® noch deutlicher herausstellen
                  kannst?
                </h3>
                <p>
                  Mein erster Schritt bei the i-engineers wird es sein, die
                  Nutzererfahrungen der heutigen Lösung genau zu analysieren und
                  direkt auf die Bedürfnisse des medizinischen Personals
                  einzugehen. Ich beabsichtige, den Fokus auf die Entwicklung
                  von Lösungen zu legen, die den Klinikalltag effizienter
                  gestalten und dabei ressourcenschonend sind. Dabei greife ich
                  auf meinen Erfahrungen mit agilen und nachhaltigen
                  Projektmanagementmethoden zurück.
                </p>
                <p>
                  Es zeichnet sich heute schon ab, dass insbesondere die
                  Patientenportalintegration in die administrativen und
                  klinischen Abläufe zu Beginn eine wesentliche Rolle spielen
                  wird. Der Nutzen für die administrative Aufnahme, Pflege und
                  Ärzte muss dabei in der Verwendung der Daten liegen, die der
                  Patient im Vorfeld liefert und dazu führen, dass damit
                  vorbereitet gearbeitet wird, um effizienter in den Prozessen
                  zu werden und auch den Nutzen ggü. dem Patienten in letzter
                  Konsequenz aufzeigen zu können.
                </p>

                <h3 className={marginTopSubstitute}>
                  Wir freuen uns auf eine lange und erfolgreiche Zusammenarbeit
                  mit Andreas G. Henkel und wünschen ihm einen guten Start in
                  seine neue Tätigkeit.
                </h3>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NewsAndreasPage;
